import "../css/style.scss";
import Headroom from "headroom.js";
import lazySizes from "lazysizes";
import InfiniteScroll from "infinite-scroll";

/* ---------------------------------------------------------------- Scroll To */

function scrollTo(element) {
  window.scroll({
    behavior: "smooth",
    left: 0,
    top: element.offsetTop,
  });
}

/* ---------------------------------------------------------------- Accordion */

function accordion() {
  if (document.querySelector(".js-accordion")) {
    // Accordion
    var accordion = document.querySelector(".js-accordion");
    var accordionItem = accordion.querySelectorAll(".c-accordion__header");

    // check if URL already has a hash
    var hash = window.location.hash ? window.location.hash.substring(1) : null;

    if (hash) {
      openItem(hash);
      scrollTo(document.getElementById(hash));
    }

    for (var i = 0, len = accordionItem.length; i < len; i++) {
      accordionItem[i].addEventListener("click", toggleAccordion);
    }

    function openItem(id) {
      var item = document.getElementById(id);

      item.classList.toggle("is-open");
      var expanded = item.querySelector("button").getAttribute("aria-expanded");
      if (expanded === "false") {
        item.querySelector("button").setAttribute("aria-expanded", "true");
      } else {
        item.querySelector("button").setAttribute("aria-expanded", "false");
      }
    }

    function toggleAccordion(event) {
      // Prevent normal click
      event.preventDefault();
      // Get originator element
      var element = event.target;
      var container = element.closest(".c-accordion__item");
      var elementId = container.id;
      var elementTitle = container.querySelector(".c-accordion__headline")
        .childNodes[0].nodeValue;

      openItem(elementId);

      history.pushState(
        {
          id: "why",
        },
        elementTitle,
        "#" + elementId
      );

      window.addEventListener(
        "popstate",
        function (event) {
          if (history.state && history.state.id === "why") {
            console.log(elementTitle);
          }
        },
        false
      );
    }
  }
}

/* ------------------------------------------------------------ Search toggle */

function searchToggle() {
  var searchOpen = document.querySelector(".js-search__open");
  var searchClose = document.querySelector(".js-search__close");
  var searchField = document.querySelector(".js-search__field");

  var html = document.getElementsByTagName("html")[0];

  searchOpen.addEventListener(
    "click",
    function () {
      html.classList.add("has-searchpanel-open");
      searchField.focus();
    },
    false
  );

  searchClose.addEventListener(
    "click",
    function () {
      html.classList.remove("has-searchpanel-open");
    },
    false
  );

  document.onkeydown = function (evt) {
    evt = evt || window.event;
    if (evt.keyCode == 27) {
      html.classList.remove("has-searchpanel-open");
    }
  };
}

/* -------------------------------------------------------------Javascript check */

function checkJavascriptEnabled() {
  document.documentElement.className =
    document.documentElement.className.replace(/\bno-js\b/g, "") + " js ";
}

/* ------------------------------------------------------------- Text clipper */

function textClippe() {
  var clipperButton = document.querySelector(".js-clipper");

  if (clipperButton) {
    clipperButton.addEventListener(
      "click",
      function () {
        var clipperContainer = this.closest(".c-clipper");
        clipperContainer.classList.toggle("is-expanded");
        console.log("touched");
      },
      false
    );
  }
}

/* ----------------------------------------------------------- Project filter */

var projectFilter = function () {
  if (document.getElementById("project-filters")) {
    // Show an element
    var show = function (elem) {
      elem.classList.remove("is-hidden");
    };

    // Hide an element
    var hide = function (elem) {
      elem.classList.add("is-hidden");
    };

    // Toggle element visibility
    var toggle = function (elem) {
      elem.classList.toggle("is-hidden");
    };

    // Get input element
    var projectFilters = document.getElementById("project-filters");

    if (projectFilters) {
      var projectFilter = projectFilters.querySelectorAll("a");

      for (var i = 0, len = projectFilter.length; i < len; i++) {
        projectFilter[i].addEventListener("click", filterProjects);
      }

      function filterProjects(event) {
        // Prevent normal click
        event.preventDefault();
        // Get originator element
        var element = event.target;
        // Remove ciurrent is-active
        var currentIsActive = document.querySelector(".is-active");
        currentIsActive.classList.remove("is-active");
        // Make this element selected
        element.classList.add("is-active");
        // Get category to show
        var category = element.getAttribute("href").split("#")[1];
        // Get projects container ul
        var projectsContainer = document.getElementById("projects");
        // Get projects
        var projects = projectsContainer.querySelectorAll("li.project");
        // Get projects of current category
        var projectsFromCategory = projectsContainer.querySelectorAll(
          ".project.project-" + category
        );

        if (category !== "all") {
          // Hide all projects
          for (var i = 0, len = projects.length; i < len; i++) {
            hide(projects[i]);
          }
          // Show only projects of selected category
          for (var i = 0, len = projectsFromCategory.length; i < len; i++) {
            show(projectsFromCategory[i]);
          }
        } else {
          // Show all projects
          for (var i = 0, len = projects.length; i < len; i++) {
            show(projects[i]);
          }
        }
      }
    }
  }
};

/* ---------------------------------------------------------- Infinite Scroll */

function infinite() {
  "use strict";

  if (document.querySelector(".js-loadmore")) {
    var button = document.querySelector(".js-loadmore");
    var buttonLabel = button.childNodes[0].nodeValue;

    var elem = document.querySelector(".c-list");
    var infScroll = new InfiniteScroll(elem, {
      path: ".js-loadmore",
      append: ".c-list__item",
      //history: 'replace',
      history: "push",
      button: ".js-loadmore",
      scrollThreshold: false,
    });

    infScroll.on("request", function () {
      console.log("loading");
      button.innerHTML = "Loading…";
    });

    infScroll.on("load", function () {
      console.log("loaded");
      button.innerHTML = buttonLabel;
    });
  }
}

/*------------------------------------------------------------------ Validate */

function validate() {
  var inputs = document.querySelectorAll("input, textarea");
  for (var i = 0; i < inputs.length; i++) {
    inputs[i].addEventListener("change", function () {
      if (!this.checkValidity()) {
        this.closest("div").classList.add("has-error");
      } else {
        this.closest("div").classList.remove("has-error");
      }
    });
  }
}

/*
 * Mailchimp AJAX form submit VanillaJS
 * Vanilla JS
 * Author: Michiel Vandewalle
 * Github author: https://github.com/michiel-vandewalle
 * Github project: https://github.com/michiel-vandewalle/Mailchimp-AJAX-form-submit-vanillaJS
 */

function subscribe() {
  if (document.getElementById("mc-embedded-subscribe-form")) {
    document
      .getElementById("mc-embedded-subscribe-form")
      .addEventListener("submit", function (e) {
        e.preventDefault();

        var formInputs = document.getElementById("js-form-inputs");
        var formReponse = document.getElementById("js-subscribe-response");

        // Check for spam
        if (e.target.checkValidity() == true) {
          // Check for spam
          if (document.getElementById("js-validate-robot").value !== "") {
            return false;
          }

          // Get url for mailchimp
          var url = this.action.replace("/post?", "/post-json?");

          // Add form data to object
          var data = "";
          //var inputs = this.querySelectorAll('#js-form-inputs input');
          var inputs = this.querySelectorAll(
            '#js-form-inputs input:not([type="radio"]), #js-form-inputs input[type="radio"]:checked'
          );
          for (var i = 0; i < inputs.length; i++) {
            data +=
              "&" + inputs[i].name + "=" + encodeURIComponent(inputs[i].value);
          }

          // Create & add post script to the DOM
          var script = document.createElement("script");
          script.src = url + data;
          console.log("Data:" + data);

          document.body.appendChild(script);

          // Callback function
          var callback = "callback";
          window[callback] = function (data) {
            // Remove post script from the DOM
            delete window[callback];
            document.body.removeChild(script);

            // Display response message
            console.log(data.status);

            if (data.status === "subscribed") {
              formReponse.innerHTML =
                "You are already subscribed to this mailing list.";
            } else {
              formInputs.style.display = "none";
              formReponse.style.display = "block";
              formReponse.innerHTML = data.msg;
            }
          };
        } else {
          console.log("invalid");

          var inputs = document.querySelectorAll("input, textarea");
          for (var i = 0; i < inputs.length; i++) {
            if (!this.checkValidity()) {
              inputs[i].closest("div").classList.add("has-error");
            }
          }
        }
      });
  }
}

/*------------------------------------------------------------------ Headroom */

function headroom() {
  // grab an element
  var myElement = document.querySelector(".c-masthead");
  // varruct an instance of Headroom, passing the element
  var headroom = new Headroom(myElement, {
    tolerance: {
      down: 10,
      up: 0,
    },
    offset: 182,
  });
  headroom.init();
}

/*---------------------------------------------------------------------- Init */
var init = function () {
  checkJavascriptEnabled();
  validate();
  subscribe();
  searchToggle();
  accordion();
  projectFilter();
  infinite();
  headroom();
};

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", init); // Document still loading so DomContentLoaded can still fire :)
} else {
  init();
}
